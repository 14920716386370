var FORM_PHYSICIAN_FINDER_CONST = {
    name: 'physicianFinder',
    key: 'wx.formPhysicianFinder',
    apiUrl: 'https://doc-finder-api.prod.ogn-v2.colddata.com/doctors',
    apiKey: 'PsAxqib6hr3anKOn3eouHHw7Hi3yFyrK',
};

let host = window.location.hostname;

if (/^((www\.)?woundscantwait\.com)$/.test(host)) {
  FORM_PHYSICIAN_FINDER_CONST.apiUrl = 'https://leads.organogenesis.com/api/doctors';
}
else if (/^((www\.)?woundscantwait\.dev)$/.test(host)) {
  FORM_PHYSICIAN_FINDER_CONST.apiUrl = 'https://leads.organogenesis.dev/api/doctors';
}
else if (/^sfsk-woundscantwait-ssi\.lndo\.site$/.test(host)) {
  FORM_PHYSICIAN_FINDER_CONST.apiUrl = 'http://sfsk-organogenesis-leads-d10.lndo.site/api/doctors';
}

// Component Declaration
var FormPhysicianFinder = function (element) {
    $('.form-select', element).selectfield();

    this.$initialContainer = $('.form-physician-finder-initial', element);
    this.$resultsContainer = $('.form-physician-finder-results', element);
    this.$resultsList = $(
        '.form-physician-finder-results-list',
        this.$resultsContainer
    );
    this.$resultsEmpty = $(
        '.form-physician-finder-results-empty',
        this.$resultsContainer
    );
    this.$initialForm = $('.component-form', this.$initialContainer);
    this.$initialZIP = $('input[name="zip"]', this.$initialContainer);
    this.$initialRadius = $('select[name="radius"]', this.$initialContainer);
    this.$initialSearchButton = $('button', this.$initialContainer); 
    this.$resultsRadius = $('select[name="radius"]', this.$resultsContainer);
    this.$resultZIP = $('.zip', this.$resultsContainer);
    this.$resultReset = $('.reset-physician-finder', this.$resultsContainer);
    this.$noResultsModal = $('#physician-finder-no-doctors');

    this.hostname = window.location.hostname;
    if (/^((www\.)?woundscantwait\.com)$/.test(hostname)) {
        FORM_PHYSICIAN_FINDER_CONST.apiUrl = 'https://leads.organogenesis.com/api/doctors';
    }
    else if (/^((www\.)?woundscantwait\.dev)$/.test(hostname)) {
        FORM_PHYSICIAN_FINDER_CONST.apiUrl = 'https://leads.organogenesis.dev/api/doctors';
    }
    else if (/^sfsk-woundscantwait-ssi\.lndo\.site$/.test(hostname)) {
        FORM_PHYSICIAN_FINDER_CONST.apiUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/api/doctors';
    }

    this.state = {
        zip: '',
        radius: '5',
    };
    this.init();
};

FormPhysicianFinder.prototype = {
    init: function () {
        this.$initialZIP.on('input', this.handleZIPChange.bind(this));
        this.$initialRadius.on('change', this.handleInitialRadius.bind(this));
        this.$initialForm.on('submit', this.handleSubmitInitialForm.bind(this));
        this.$resultReset.on('click', this.handleReset.bind(this));
        this.$resultsRadius.on(
            'change',
            this.handleEmptySelectChange.bind(this)
        );
        this.$noResultsModal.on('hidden.bs.modal', this.handleReset.bind(this));
    },
    handleZIPChange: function (event) {
        var value = $(event.target).val();
        var shouldDisableButton = !value || value.length < 5;
        this.$initialSearchButton.prop('disabled', shouldDisableButton);
        this.state.zip = value;
        this.$resultZIP.html(value);
    },
    handleInitialRadius: function (event) {
        var value = $(event.target).val();
        this.state.radius = value;
    },
    handleSubmitInitialForm: function (event) {
        event.preventDefault();
        var zip = this.state.zip;
        var radius = this.state.radius;
        $.ajax({
            method: 'GET',
            url: FORM_PHYSICIAN_FINDER_CONST.apiUrl,
            data: $.param(this.state),
            contentType: 'application/json',
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader(
                    'Authorization',
                    'Bearer ' + FORM_PHYSICIAN_FINDER_CONST.apiKey
                );
            },
        }).then(this.handleSuccess.bind(this), this.handleFailed.bind(this));
        return false;
    },
    handleReset: function (event) {
        event.preventDefault();
        this.$initialContainer.show();
        this.$resultsContainer.hide();
        return false;
    },
    handleSuccess: function (res) {
        var data = res.results;
        var count = res.total;
        this.$resultsEmpty.hide();
        this.$resultsList.hide();
        this.$resultsContainer.removeClass('no-results');

        var $container = this.$resultsList;
        $container.empty();
        if (Array.isArray(data) && count > 0) {
            data.forEach(function (doctor) {
                var $row = $('<tr>');
                $row.append($('<td class="col-state">').html(doctor.state));
                $row.append($('<td class="col-zip">').html(doctor.zipcode));
                $row.append(
                    $('<td class="col-hcp">').html(
                        doctor.firstname + ' ' + doctor.lastname
                    )
                );
                $row.append(
                    $('<td class="col-specialty">').html(
                        doctor.primaryspecialty
                    )
                );
                $row.append(
                    $('<td class="col-institution">').html(
                        '<a href="' +
                            getMapUrl(doctor) +
                            '" target="_blank" class="action-link">' +
                            doctor.institution +
                            '</a>'
                    )
                );
                $row.append(
                    $('<td class="col-phone">').html(
                        '<span>' +
                            doctor.phone +
                            '</span><a href="tel:' +
                            doctor.phone +
                            '" class="call"><span>Call</span></a>'
                    )
                );

                $container.append($row);
            });
            $container.show();
        }

        if (count < 2) {
            this.$resultsEmpty.show();
        }

        if (count < 1) {
            this.$resultsContainer.addClass('no-results');
        }

        this.$initialContainer.hide();
        this.$resultsContainer.show();
    },
    handleFailed: function (e) {
        console.log('--> handle fetch data fail: ', e);
    },
    handleEmptySelectChange: function (event) {
        var value = $(event.target).val();
        this.state.radius = value;
        this.$initialRadius
            .find('option[value="' + this.state.radius + '"]')
            .prop('selected', true);

        // Add custom metric tags
        addMetricTag('phyfinder_zip_adjust', this.state.zip);
        addMetricTag('phyfinder_range_adjust', this.state.radius);
        this.$initialForm.trigger('submit');
    },
};

function getMapUrl(doctor) {
    return (
        'https://www.google.com/maps/search/?api=1&query=' +
        encodeURIComponent(
            doctor.institution +
                ' ' +
                doctor.city +
                ' ' +
                doctor.state +
                ' ' +
                doctor.zipcode
        )
    );
}

function _jQueryInterfaceFormPhysicianFinder(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(FORM_PHYSICIAN_FINDER_CONST.key);

        if (!data) {
            data = new FormPhysicianFinder(this);
            $element.data(FORM_PHYSICIAN_FINDER_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[FORM_PHYSICIAN_FINDER_CONST.name] = _jQueryInterfaceFormPhysicianFinder;
$.fn[FORM_PHYSICIAN_FINDER_CONST.name].Constructor = FormPhysicianFinder;
$.fn[FORM_PHYSICIAN_FINDER_CONST.name].noConflict = () => {
    $.fn[FORM_PHYSICIAN_FINDER_CONST.name] =
        $.fn[FORM_PHYSICIAN_FINDER_CONST.name];
    return _jQueryInterfaceFormPhysicianFinder;
};

$(function () {
    $('.component-form-physician-finder').physicianFinder();
});
