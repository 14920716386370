var TOPPER_CONST = {
    name: 'topper',
    key: 'wx.topper',
};

// Component Declaration
var Topper = function (element) {
    this.$element = $(element);
    this.$body = $('body');

    this.init();
};

Topper.prototype = {
    init: function () {
        this.$body.addClass('template-topper');
    },
};

function _jQueryInterfaceTopper(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(TOPPER_CONST.key);

        if (!data) {
            data = new Topper(this, config);
            $element.data(TOPPER_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[TOPPER_CONST.name] = _jQueryInterfaceTopper;
$.fn[TOPPER_CONST.name].Constructor = Topper;
$.fn[TOPPER_CONST.name].noConflict = () => {
    $.fn[TOPPER_CONST.name] = $.fn[TOPPER_CONST.name];
    return _jQueryInterfaceTopper;
};

$(function () {
    $('#component-topper').topper();
});
