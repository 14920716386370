var BEFORE_AFTER_SLIDER_CONST = {
    name: 'beforeAfterSlider',
    key: 'wx.beforeAfterSlider',
};

// Component Declaration
var BeforeAfterSlider = function (element) {
    this.$element = $(element);
    this.$desktopSlider = $('#desktop-slider', element);
    this.$before = $('#before-image', element);
    this.$beforeImage = $('img', this.$before);
    this.$beforeMessage = $('#before-message', this.$before);
    this.$beforeMessageBG = $('#before-message-bg', this.$before);
    this.$after = $('#after-image', element);
    this.$afterMessage = $('#after-message', this.$after);
    this.$afterMessageBG = $('#after-message-bg', this.$after);
    this.$dragToolbar = $('#drag-toolbar', element);
    this.$dragLeft = $('#drag-left', element);
    this.$dragRight = $('#drag-right', element);
    this.$callout = $('#callout', element);

    this.$mobileSlider = $('#mobile-slider', element);
    this.$mobileBeforeImage = $('.before-image', this.$mobileSlider);
    this.$mobileBeforeMessage = $('.before-message', this.$mobileSlider);
    this.$mobileBeforeMessageBG = $('.before-message-bg', this.$mobileSlider);
    this.$mobileAfterImage = $('.after-image', this.$mobileSlider);
    this.$mobileAfterMessage = $('.after-message', this.$mobileSlider);
    this.$mobileAfterMessageBG = $('.after-message-bg', this.$mobileSlider);

    this.center = 0;
    this.isMouseInsideDocument = false;
    this.BEFORE_LIMIT = 495;
    this.AFTER_LIMIT = 1500;

    this.init();
};

BeforeAfterSlider.prototype = {
    init: function () {
        this.center = $(document).width() / 2;
        this.isMouseInsideDocument = true;

        // Set initial width for the before image
        this.$beforeImage.width(this.$desktopSlider.width());

        $(window).on('resize', this.adjustOnResize.bind(this));
        $(document).on('mouseleave', this.setMousePosition.bind(this, false));
        $(document).on('mouseenter', this.setMousePosition.bind(this, true));
        this.$dragLeft.on('click', this.showBeforeDesktop.bind(this));
        this.$dragRight.on('click', this.showAfterDesktop.bind(this));
        this.$element.on('mouseleave', this.reCenterSlider.bind(this));

        this.$mobileBeforeImage.on('click', this.showBeforeMobile.bind(this));
        this.$mobileAfterImage.on('click', this.showAfterMobile.bind(this));
        this.$mobileBeforeMessage.on(
            'click',
            this.showBeforeMessageMobile.bind(this)
        );
        this.$mobileAfterMessage.on(
            'click',
            this.showAfterMessageMobile.bind(this)
        );
    },
    adjustOnResize: function () {
        this.$beforeImage.width(this.$desktopSlider.width());
        this.center = $(document).width() / 2;
    },
    setMousePosition: function (insideDocument) {
        this.isMouseInsideDocument = insideDocument;
    },
    showBeforeDesktop: function () {
        addMetricTag('hp_campaign_left');
        this.slideIt(this.BEFORE_LIMIT);
        this.$dragRight.removeClass('active');
        this.$dragLeft.addClass('active');

        if (this.$beforeMessage.is(':not(:hidden)')) {
            this.$beforeMessage.fadeOut(200);
        }

        if (this.$beforeMessageBG.is(':not(:hidden)')) {
            this.$beforeMessageBG.fadeOut(300);
        }

        if (this.$beforeImage.find('.blur').is(':not(:hidden)')) {
            this.$beforeImage.find('.blue:not(:hidden)').fadeOut(300);
        }

        if (this.$afterMessageBG.is(':hidden')) {
            this.$afterMessageBG.fadeIn(400);
            if (this.$afterMessage.is(':hidden')) {
                this.$afterMessage.fadeIn(300);
            }
        }
    },
    showAfterDesktop: function () {
        addMetricTag('hp_campaign_right');
        this.slideIt(this.AFTER_LIMIT);
        this.$dragRight.addClass('active');
        this.$dragLeft.removeClass('active');

        if (this.$afterMessage.is(':not(:hidden)')) {
            this.$afterMessage.fadeOut(200);
        }

        if (this.$afterMessageBG.is(':not(:hidden)')) {
            this.$afterMessageBG.fadeOut(300);
        }

        if (this.$beforeImage.find('.blur').is(':hidden')) {
            this.$beforeImage.find('.blur').fadeIn(300);
        }

        if (this.$beforeMessageBG.is(':hidden')) {
            this.$beforeMessageBG.fadeIn(400);
            if (this.$beforeMessage.is(':hidden')) {
                this.$beforeMessage.fadeIn(300);
            }
        }
    },
    showBeforeMobile: function () {
        addMetricTag('hp_campaign_left');
        const that = this;
        this.$mobileAfterImage.addClass('blur');
        this.$mobileBeforeImage.removeClass('blur');
        this.$mobileAfterMessage.hide();
        this.$mobileAfterMessageBG.hide();
        this.$mobileBeforeMessageBG.fadeIn(300, function () {
            that.$mobileBeforeMessage.css('display', 'flex').fadeIn();
        });
    },
    showAfterMobile: function () {
        addMetricTag('hp_campaign_right');
        const that = this;
        this.$mobileBeforeImage.addClass('blur');
        this.$mobileAfterImage.removeClass('blur');
        this.$mobileBeforeMessage.hide();
        this.$mobileBeforeMessageBG.hide();
        this.$mobileAfterMessageBG.fadeIn(300, function () {
            that.$mobileAfterMessage.css('display', 'flex').fadeIn();
        });
    },
    showBeforeMessageMobile: function () {
        this.$mobileAfterImage.removeClass('blur');
        this.$mobileBeforeMessageBG.hide();
        this.$mobileBeforeMessage.hide();
    },
    showAfterMessageMobile: function () {
        this.$mobileBeforeImage.removeClass('blur');
        this.$mobileAfterMessageBG.hide();
        this.$mobileAfterMessage.hide();
    },
    slideIt: function (x) {
        const transform = Math.max(0, Math.min(x, this.$desktopSlider.width()));
        this.$before.width(transform);
        this.$dragToolbar.css('left', transform + 'px');
        this.$callout.css('left', transform - 170 + 'px');
    },
    reCenterSlider: function () {
        const that = this;

        // Delay to detect if mouse is not outside the document
        setTimeout(function () {
            if (
                that.isMouseInsideDocument &&
                that.$dragToolbar.width() !== that.center
            ) {
                that.slideIt(that.center);
                that.$dragLeft.removeClass('active');
                that.$dragRight.removeClass('active');
            }
        }, 100);
    },
};

function _jQueryInterfaceBeforeAfterSlider(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(BEFORE_AFTER_SLIDER_CONST.key);

        if (!data) {
            data = new BeforeAfterSlider(this);
            $element.data(BEFORE_AFTER_SLIDER_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[BEFORE_AFTER_SLIDER_CONST.name] = _jQueryInterfaceBeforeAfterSlider;
$.fn[BEFORE_AFTER_SLIDER_CONST.name].Constructor = BeforeAfterSlider;
$.fn[BEFORE_AFTER_SLIDER_CONST.name].noConflict = () => {
    $.fn[BEFORE_AFTER_SLIDER_CONST.name] = $.fn[BEFORE_AFTER_SLIDER_CONST.name];
    return _jQueryInterfaceBeforeAfterSlider;
};

$(function () {
    $('.component-slider').beforeAfterSlider();
});
