var BACK_TO_TOP_CONST = {
    name: 'backToTop',
    key: 'wx.button',
};

// Component Declaration
var BackToTop = function (element) {
    this.$element = $(element);
    this.init();
};

BackToTop.prototype = {
    init: function () {
        this.$element.on('click', this.scrollToTop.bind(this));
    },
    scrollToTop: function (e) {
        e.preventDefault();
        $('html, body').animate(
            {
                scrollTop: 0,
            },
            100
        );
    },
};

function _jQueryInterfaceBackToTop(config) {
    return this.each(function () {
        var $element = $(this);
        var btn = $element.data(BACK_TO_TOP_CONST.key);

        if (!btn) {
            btn = new BackToTop(this);
            $element.data(BACK_TO_TOP_CONST.key, btn);
        }
    });
}

$.fn[BACK_TO_TOP_CONST.name] = _jQueryInterfaceBackToTop;
$.fn[BACK_TO_TOP_CONST.name].Constructor = BackToTop;
$.fn[BACK_TO_TOP_CONST.name].noConflict = function () {
    $.fn[BACK_TO_TOP_CONST.name] = $.fn[BACK_TO_TOP_CONST.name];
    return _jQueryInterfaceBackToTop;
};

$(function () {
    $('.component-back-to-top').backToTop();
});
