$(function () {
    if ($('.component-header').length) {
        $('#toggle', '.component-header').on('click', function () {
            $('.component-header').toggleClass('collapsed');
            $('body').toggleClass('scroll-disabled');
        });

        $(window).on('resize', function () {
            $('.component-header').removeClass('collapsed');
            $('body').removeClass('scroll-disabled');
        });
    }

    $(window).scroll(function () {
        let nowScrollTop = $(this).scrollTop();
        if (nowScrollTop > 0) {
            $('.component-sidebar').addClass('scrolled');
        } else {
            $('.component-sidebar').removeClass('scrolled');
        }
    });
});
