var PHYSICIAN_FINDER_EXIT_LINK_CONST = {
    name: 'physicianFinderExitLink',
    key: 'wx.physicianFinderExitLink',
};

var PhysicianFinderExitLink = function (element) {
    this.$element = $(element);
    this.noActionExitModalId = 'physician-finder-no-action-exit';
    var modal = document.getElementById(this.noActionExitModalId);
    if (!this.$element.length || !modal) {
        return;
    }
    this.noActionExitModal = new bootstrap.Modal(modal);
    this.$leaveSiteButton = this.$element.find(
        '#' + this.noActionExitModalId + ' .leave-page'
    );
    this.init();
};

PhysicianFinderExitLink.prototype = {
    init: function () {
        // Add 'no-action-exit-link' class to all links
        this.$element
            .find('a:not(.action-link,.ignore-action-link)')
            .addClass('no-action-exit-link');

        // Add handler for no action exit links
        this.$element.on(
            'click',
            'a.no-action-exit-link',
            this.handleNoActionExitClick.bind(this)
        );

        // Handle click on action links
        this.$element.on(
            'click',
            'a.action-link',
            this.handleActionClick.bind(this)
        );

        // Handle leave site button click in modal
        this.$leaveSiteButton.on('click', this.handleLeaveSiteClick.bind(this));

        // On modal close - don't display it again
        $('#' + this.noActionExitModalId).on(
            'hidden.bs.modal',
            this.disableLeaveSiteModal.bind(this)
        );
    },
    handleNoActionExitClick: function (event) {
        event.preventDefault();

        // Get the target element user clicked on.
        // Make sure it is the <a> element and not the <span> inside it
        var $el =
            $(event.target).closest('a').length !== 0
                ? $(event.target).closest('a')
                : $(event.target);

        // Display Exit modal
        this.noActionExitModal.show();
        var link = {
            href: $el.prop('href'),
            blank: $el.prop('target') === '_blank',
        };
        this.$leaveSiteButton.data('href', link.href).data('blank', link.blank);

        return false;
    },
    handleActionClick: function (event) {
        this.disableLeaveSiteModal();

        // Add custom tracking tag
        addMetricTag('phyfinder_view_location');
    },
    handleLeaveSiteClick: function (event) {
        event.preventDefault();
        if (this.$leaveSiteButton.data('blank')) {
            window.open(this.$leaveSiteButton.data('href'), '_blank');
            return false;
        } else {
            location.href = this.$leaveSiteButton.data('href');
        }
    },
    disableLeaveSiteModal: function () {
        this.$element
            .find('a.no-action-exit-link')
            .removeClass('no-action-exit-link');
    },
};

function _jQueryInterfacePhysicianFinderExitLink(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(PHYSICIAN_FINDER_EXIT_LINK_CONST.key);

        if (!data) {
            data = new PhysicianFinderExitLink(this);
            $element.data(PHYSICIAN_FINDER_EXIT_LINK_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[PHYSICIAN_FINDER_EXIT_LINK_CONST.name] =
    _jQueryInterfacePhysicianFinderExitLink;
$.fn[PHYSICIAN_FINDER_EXIT_LINK_CONST.name].Constructor =
    PhysicianFinderExitLink;
$.fn[PHYSICIAN_FINDER_EXIT_LINK_CONST.name].noConflict = () => {
    $.fn[PHYSICIAN_FINDER_EXIT_LINK_CONST.name] =
        $.fn[PHYSICIAN_FINDER_EXIT_LINK_CONST.name];
    return _jQueryInterfacePhysicianFinderExitLink;
};

$(function () {
    $('body#physician-finder').physicianFinderExitLink();
});
