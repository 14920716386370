// If receiver is PTP, the form data will be sent to PTP Webform.
const RECEIVER = 'HubSpot';

var FORM_REGISTRATION_CONST = {
    name: 'registration',
    key: 'wx.registrationForm',
    cookie: {
        registration: 'registered',
        hutk: 'hubspotutk',
    },
};
var GUARDED_LINK_CONST = {
    name: 'guardedLink',
    key: 'wx.guardedLink',
};

var isFormRegistrationCookieSet = function () {
    return getCookie(FORM_REGISTRATION_CONST.cookie.registration);
};

var setFormRegistrationCookie = function () {
    setCookie(FORM_REGISTRATION_CONST.cookie.registration, 'true', 36500);
};

// Component Declaration
var FormRegistration = function (element) {
    $('.form-control', element).textfield();
    $('.form-select', element).selectfield();

    this.$element = $(element);
    this.$form = $('form', element);
    this.$termsAgree = $('input[name="termsAgree"]', element);
    this.$button = $('button[type=submit]', element);
    this.$formHolder = $('#form-registration-form-holder', element);
    this.$successMessageHolder = $(
        '#form-registration-message-success',
        element
    );
    this.$noRegistrationMessageHolder = $(
        '#form-registration-message-no-registration',
        element
    );

    this.$hostname = window.location.hostname;
    switch (this.$hostname) {
        case 'localhost':
            // HubSpot Sandbox Account
            this.$hubspot = {
                accessToken: 'pat-eu1-18f4f8c6-c0b5-43b4-813c-b2e1f0bc20a6',
                portalId: '139584591',
                formId: 'a24005ab-d9ca-437a-8b21-d82b86bdc2ca',
                subscriptionTypeId: 182897238,
            };
            break;
        default:
            this.$hubspot = {
                accessToken: 'pat-na1-9d7fc4b5-f42f-4d2a-aa16-8d4a95bb34d6',
                portalId: '24433678',
                formId: 'd4d9ebc7-ed71-41b7-8319-6b6897f7893d',
                subscriptionTypeId: 84016497,
            };
            break;
    }

    this.ptpDataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';

    if (/^woundscantwait\.com$/.test(this.$hostname) || /^www\.woundscantwait\.com$/.test(this.$hostname)) {
      this.ptpDataUrl = 'https://leads.organogenesis.com/webform_rest/submit';
    } else if (/^woundscantwait\.dev$/.test(this.$hostname) || /^www\.woundscantwait\.dev$/.test(this.$hostname)) {
      this.ptpDataUrl = 'https://leads.organogenesis.dev/webform_rest/submit';
    } else if (/^sfsk-woundscantwait-ssi\.lndo\.site$/.test(this.$hostname)) {
      this.ptpDataUrl = 'https://sfsk-organogenesis-leads-d10.lndo.site/webform_rest/submit';
    }

    this.init();
};

FormRegistration.prototype = {
    init: function () {
        this.$termsAgree.on('change', this.handleChangeTermsAgree.bind(this));
        this.$form.on('submit', this.handleFormSubmit.bind(this));
        this.$element
            .on('hidden.bs.modal', this.handleClose.bind(this))
            .on('show.bs.modal', this.handleShow.bind(this));
    },
    handleChangeTermsAgree: function (event) {
        if (event.target !== event.currentTarget) {
            return;
        }
        var isButtonDisabled = !$(event.target).is(':checked');
        if (isButtonDisabled) {
            this.$button.prop('disabled', true);
            return;
        }
        this.$button.prop('disabled', false);
        return;
    },
    handleFormSubmit: function () {
        this.$button.prop('disabled', true);
        this.$formHolder.hide();
        this.$successMessageHolder.show();

        var formData = JSON.stringify({
            fields: [
                {
                    objectTypeId: '0-1',
                    name: 'email',
                    value: this.$form.find("input[name='email']").val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'firstname',
                    value: this.$form.find("input[name='firstName']").val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'lastname',
                    value: this.$form.find("input[name='lastName']").val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'user_type',
                    value: this.$form.find('#userType').val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'ulcer_type',
                    value: this.$form
                        .find("input[name='ulcerType']:checked")
                        .val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'address',
                    value: this.$form.find("input[name='address']").val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'apt',
                    value: this.$form.find("input[name='address2']").val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'city',
                    value: this.$form.find("input[name='city']").val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'state',
                    value: this.$form.find('#state').val(),
                },
                {
                    objectTypeId: '0-1',
                    name: 'zip',
                    value: this.$form.find("input[name='zip']").val(),
                },
            ],
            context: {
                hutk: getCookie(FORM_REGISTRATION_CONST.cookie.hutk),
                pageUri: window.location.href,
                pageName: document.title,
            },
            legalConsentOptions: {
                consent: {
                    consentToProcess: true,
                    text: 'I authorize Organogenesis and those working with or on behalf of Organogenesis to contact me via email, mail, or phone to conduct marketing activities.',
                    communications: [
                        {
                            value: true,
                            subscriptionTypeId:
                                this.$hubspot.subscriptionTypeId,
                            text: 'I agree to receive marketing communications from Organogenesis and those working with or on behalf of Organogenesis.',
                        },
                    ],
                },
            },
        });
        setFormRegistrationCookie();

        if (RECEIVER === 'PTP') {
            let ptpFormData =  JSON.stringify({
                'webform_id': 'woundscantwait_registration',
                'email': this.$form.find("input[name='email']").val(),
                'firstname': this.$form.find("input[name='firstName']").val(),
                'lastname': this.$form.find("input[name='lastName']").val(),
                'user_type': this.$form.find('#userType').val(),
                'ulcer_type': this.$form.find("input[name='ulcerType']:checked").val(),
                'address': this.$form.find("input[name='address']").val(),
                'apt': this.$form.find("input[name='address2']").val(),
                'city': this.$form.find("input[name='city']").val(),
                'state': this.$form.find('#state').val(),
                'zip': this.$form.find("input[name='zip']").val(),
                'pageUri': window.location.href,
                'pageName': document.title,
                'consentToProcess': true,
                'communications': true,
            })

            $.ajax({
                method: 'POST',
                url: this.ptpDataUrl,
                data: ptpFormData,
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
            });

            return false;
        }
        else {
          // Submit data to Hubspot
          $.ajax({
              url:
                  'https://api.hsforms.com/submissions/v3/integration/submit/' +
                  this.$hubspot.portalId +
                  '/' +
                  this.$hubspot.formId,
              headers: {
                  Authorization: 'Bearer ' + this.$hubspot.accessToken,
                  'Content-Type': 'application/json',
              },
              method: 'POST',
              data: formData,
          });

          return false;
        }
    },
    handleClose: function () {
        this.$button.prop('disabled', true);
        this.$form.trigger('reset');
        this.$formHolder.show();
        this.$successMessageHolder.hide();
        this.$noRegistrationMessageHolder.hide();
    },
    handleShow: function () {
        if (isFormRegistrationCookieSet()) {
            this.$formHolder.hide();
            this.$noRegistrationMessageHolder.show();
        } else {
            // Embed HubSpot form in iframe but hide it to get form views number
            switch (this.$hostname) {
                case 'localhost':
                    this.$form.append(
                        '<iframe style="display:none" width="1" height="1" src="https://share.hsforms.com/1okAFq9nKQ3qLIdgrhr3Cyg2b3s5r" frameborder="0"></iframe>'
                    );
                    break;
                default:
                    this.$form.append(
                        '<iframe style="display:none" width="1" height="1" src="https://share.hsforms.com/11Nnrx-1xQbeDGWtol_eJPQejp5a" frameborder="0"></iframe>'
                    );
                    break;
            }
        }
    },
};

function _jQueryInterfaceFormRegistration(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(FORM_REGISTRATION_CONST.key);

        if (!data) {
            data = new FormRegistration(this);
            $element.data(FORM_REGISTRATION_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[FORM_REGISTRATION_CONST.name] = _jQueryInterfaceFormRegistration;
$.fn[FORM_REGISTRATION_CONST.name].Constructor = FormRegistration;
$.fn[FORM_REGISTRATION_CONST.name].noConflict = () => {
    $.fn[FORM_REGISTRATION_CONST.name] = $.fn[FORM_REGISTRATION_CONST.name];
    return _jQueryInterfaceFormRegistration;
};

var GuardedLink = function (element) {
    this.$element = $(element);
    var modal = document.getElementById('form-registration');
    if (!this.$element.length || !modal) {
        return;
    }

    this.formRegistrationModal = new bootstrap.Modal(modal);

    this.init();
};

GuardedLink.prototype = {
    init: function () {
        this.$element.on('click', this.handleClick.bind(this));
    },
    handleClick: function (event) {
        event.preventDefault();

        var link = {
            href: this.$element.prop('href'),
            blank: this.$element.prop('target') === '_blank',
        };
        if (link.href && link.href.length && isFormRegistrationCookieSet()) {
            if (link.blank) {
                window.open(link.href, '_blank');
                return false;
            } else {
                window.location.href = link.href;
            }
            return;
        }

        this.formRegistrationModal.show();

        return false;
    },
};

function _jQueryInterfaceGuardedLink(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(GUARDED_LINK_CONST.key);

        if (!data) {
            data = new GuardedLink(this);
            $element.data(GUARDED_LINK_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[GUARDED_LINK_CONST.name] = _jQueryInterfaceGuardedLink;
$.fn[GUARDED_LINK_CONST.name].Constructor = GuardedLink;
$.fn[GUARDED_LINK_CONST.name].noConflict = () => {
    $.fn[GUARDED_LINK_CONST.name] = $.fn[GUARDED_LINK_CONST.name];
    return _jQueryInterfaceGuardedLink;
};

$(function () {
    $('.component-form-registration').registration();
    $('.guarded-link').guardedLink();
});
