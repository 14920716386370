var VIDEO_MODAL_CONST = {
    name: 'videoModal',
    key: 'wx.video',
};

// Component Declaration
var VideoModal = function (element) {
    this.$element = $(element);
    this.$videoPlayer = $('.component-video', element);
    this.$video = videojs(this.$videoPlayer.get(0));
    this.$videoAnalyticsId = $('.container-video', element).data(
        'analytics-id'
    );
    this.$callout = $('.callout', element);

    this.init();
};

VideoModal.prototype = {
    init: function () {
        this.$element
            .on('hidden.bs.modal', this.handleClose.bind(this))
            .on('shown.bs.modal', this.handleOpen.bind(this));

        this.sendAnalytics(this.$videoAnalyticsId);

        var showCallout = this.showCallout.bind(this);
        this.$video.on('pause', function (e) {
            showCallout();
        });

        var hideCallout = this.hideCallout.bind(this);
        this.$video.on('play', function (e) {
            hideCallout();
        });
    },
    handleOpen: function () {
        this.$video.play();
    },
    handleClose: function () {
        this.$video.pause();
    },
    showCallout: function () {
        this.$callout.addClass('shown');
    },
    hideCallout: function () {
        this.$callout.removeClass('shown');
    },
    sendAnalytics: function (eventId) {
        var markers = {
            10: true,
            25: true,
            50: true,
            75: true,
            90: true,
        };
        this.$video.on('play', function () {
            dataLayer.push({
                event: eventId,
                value: 'START',
            });
        });
        this.$video.on('timeupdate', function (e) {
            var percentPlayed = Math.floor(
                (this.currentTime() * 100) / this.duration()
            ).toString();
            if (markers[percentPlayed]) {
                markers[percentPlayed] = false;
                dataLayer.push({
                    event: eventId,
                    value: percentPlayed + '%',
                });
            }
        });
        this.$video.on('pause', function (e) {
            dataLayer.push({
                event: eventId,
                value: 'STOP',
            });
        });
        this.$video.on('ended', function () {
            dataLayer.push({
                event: eventId,
                value: 'COMPLETE',
            });
            // Add metrics tags
            addMetricTag('resources_linda_vid_completion');
        });
    },
};

function _jQueryInterfaceVideo(config) {
    return this.each(function () {
        var $element = $(this);
        var btn = $element.data(VIDEO_MODAL_CONST.key);

        if (!btn) {
            btn = new VideoModal(this);
            $element.data(VIDEO_MODAL_CONST.key, btn);
        }
    });
}

$.fn[VIDEO_MODAL_CONST.name] = _jQueryInterfaceVideo;
$.fn[VIDEO_MODAL_CONST.name].Constructor = VideoModal;
$.fn[VIDEO_MODAL_CONST.name].noConflict = () => {
    $.fn[VIDEO_MODAL_CONST.name] = $.fn[VIDEO_MODAL_CONST.name];
    return _jQueryInterfaceVideo;
};

$(function () {
    $('.component-video-modal').videoModal();
});
