var HEALING_CTA_LINK_CONST = {
    name: 'healingCtaLink',
    key: 'wx.healingCtaLink',
};

var HealingCtaLink = function (element) {
    this.$element = $(element);
    var modal = document.getElementById('form-registration');
    if (!this.$element.length || !modal) {
        return;
    }

    this.formRegistrationModal = new bootstrap.Modal(modal);

    this.init();
};

HealingCtaLink.prototype = {
    init: function () {
        this.$element.on('click', this.handleClick.bind(this));
    },
    handleClick: function (event) {
        event.preventDefault();

        this.formRegistrationModal.show();

        return false;
    },
};

function _jQueryInterfaceHealingCtaLink(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(HEALING_CTA_LINK_CONST.key);

        if (!data) {
            data = new HealingCtaLink(this);
            $element.data(HEALING_CTA_LINK_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[HEALING_CTA_LINK_CONST.name] = _jQueryInterfaceHealingCtaLink;
$.fn[HEALING_CTA_LINK_CONST.name].Constructor = HealingCtaLink;
$.fn[HEALING_CTA_LINK_CONST.name].noConflict = () => {
    $.fn[HEALING_CTA_LINK_CONST.name] = $.fn[HEALING_CTA_LINK_CONST.name];
    return _jQueryInterfaceHealingCtaLink;
};

$(function () {
    $('.healing-cta-link').healingCtaLink();
});
