var FORM_HEALING_NAVIGATOR_CONST = {
    name: 'healingNavigator',
    key: 'wx.form',
};

// Component Declaration
var NavigatorForm = function (element) {
    this.$element = $(element);
    this.$vluCheckbox = $('input[name="vlu"]', element);
    this.$dfuCheckbox = $('input[name="dfu"]', element);
    this.$quizQuestion = $('.quiz-heading', element);
    this.$buttonContainer = $('.button', element);
    this.$button = $('button[type=button]', element);
    this.$results = $('.results', element);
    this.$resultsHeading = $('.results-heading', element);
    this.$vluResults = $('.vlu-resources', element);
    this.$dfuResults = $('.dfu-resources', element);
    this.$retakeQuizLink = $('.retake-quiz a', element);

    this.init();
};

NavigatorForm.prototype = {
    init: function () {
        this.$vluCheckbox.on('change', this.handleSelection.bind(this));
        this.$dfuCheckbox.on('change', this.handleSelection.bind(this));
        this.$button.on('click', this.handleSubmit.bind(this));
        this.$retakeQuizLink.on('click', this.handleRetakeQuiz.bind(this));
    },
    handleSelection: function (event) {
        if (event.target !== event.currentTarget) {
            return;
        }
        var isButtonEnabled = this.canSubmit();
        if (!isButtonEnabled) {
            this.$button.prop('disabled', true);
            return;
        }
        this.$button.prop('disabled', false);
        return;
    },
    handleSubmit: function () {
        this.showResults();
        this.$vluCheckbox.attr('disabled', true);
        this.$dfuCheckbox.attr('disabled', true);
        return false;
    },
    handleRetakeQuiz: function () {
        // Track click on Retake Quiz link
        if (
            this.$vluCheckbox.prop('checked') &&
            !this.$dfuCheckbox.prop('checked')
        ) {
            addMetricTag('healingnav_retake_vlu');
        }

        if (
            !this.$vluCheckbox.prop('checked') &&
            this.$dfuCheckbox.prop('checked')
        ) {
            addMetricTag('healingnav_retake_dfu');
        }

        this.$vluCheckbox.prop('checked', false);
        this.$dfuCheckbox.prop('checked', false);

        this.$quizQuestion.fadeIn();
        this.$buttonContainer.fadeIn();

        this.$results.hide();
        this.$vluResults.hide();
        this.$dfuResults.hide();

        this.$vluCheckbox.attr('disabled', false);
        this.$dfuCheckbox.attr('disabled', false);
        return false;
    },
    showResults: function () {
        this.$quizQuestion.hide();
        this.$buttonContainer.hide();
        this.$results.fadeIn();

        if (this.isVluSelected()) {
            this.$vluResults.fadeIn();
        }

        if (this.isDfuSelected()) {
            this.$dfuResults.fadeIn();
        }

        // Add tracking code based on selected options
        if (this.isVluSelected() && this.isDfuSelected()) {
            addMetricTag('healingnav_both');
        } else if (this.isVluSelected()) {
            addMetricTag('healingnav_vlu');
        } else if (this.isDfuSelected()) {
            addMetricTag('healingnav_dfu');
        }
    },
    isVluSelected: function () {
        return this.$vluCheckbox.is(':checked');
    },
    isDfuSelected: function () {
        return this.$dfuCheckbox.is(':checked');
    },
    canSubmit: function () {
        return this.isVluSelected() || this.isDfuSelected();
    },
};

function _jQueryInterfaceNavigatorForm(config) {
    return this.each(function () {
        var $element = $(this);
        var data = $element.data(FORM_HEALING_NAVIGATOR_CONST.key);

        if (!data) {
            data = new NavigatorForm(this);
            $element.data(FORM_HEALING_NAVIGATOR_CONST.key, data);
        }

        if (config === 'close') {
            data[config](this);
        }
    });
}

$.fn[FORM_HEALING_NAVIGATOR_CONST.name] = _jQueryInterfaceNavigatorForm;
$.fn[FORM_HEALING_NAVIGATOR_CONST.name].Constructor = NavigatorForm;
$.fn[FORM_HEALING_NAVIGATOR_CONST.name].noConflict = () => {
    $.fn[FORM_HEALING_NAVIGATOR_CONST.name] =
        $.fn[FORM_HEALING_NAVIGATOR_CONST.name];
    return _jQueryInterfaceNavigatorForm;
};

$(function () {
    $('.component-healing-navigator').healingNavigator();
});
