function addMetricTag(name, value) {
    var hasValue = !(typeof value == 'undefined');
    var isMobile = window.innerWidth < 991;

    // Append _m for tags on mobile
    var eventName = !isMobile ? name : name + '_m';

    // Push the event
    if (!hasValue) {
        dataLayer.push({ event: eventName });
    } else {
        dataLayer.push({ [eventName]: value });
    }
}

function addMetricTagPerPage(el) {
    var page = window.location.pathname;
    switch (el) {
        // DFU Brochure
        case 'dfu:brochure':
            switch (page) {
                case '/physician-finder/':
                    addMetricTag('phyfinder_dfu_brochure');
                    break;
                case '/resources/':
                    addMetricTag('resources_dfu_brochure');
                    break;
                case '/healing-navigator/':
                    addMetricTag('healingnav_dfu_brochure_pdf');
                    break;
            }
            break;

        // VLU Brochure
        case 'vlu:brochure':
            switch (page) {
                case '/physician-finder/':
                    addMetricTag('phyfinder_vlu_brochure');
                    break;
                case '/resources/':
                    addMetricTag('resources_vlu_brochure');
                    break;
                case '/healing-navigator/':
                    addMetricTag('healingnav_vlu_brochure_pdf');
                    break;
            }
            break;

        // DFU Journal
        case 'dfu:journal':
            switch (page) {
                case '/resources/':
                    addMetricTag('resources_dfu_journal');
                    break;
                case '/healing-navigator/':
                    addMetricTag('healingnav_dfu_journal_pdf');
                    break;
            }
            break;

        // VLU Journal
        case 'vlu:journal':
            switch (page) {
                case '/resources/':
                    addMetricTag('resources_vlu_journal');
                    break;
                case '/healing-navigator/':
                    addMetricTag('healingnav_vlu_journal_pdf');
                    break;
            }
            break;

        // Callout: Help for Healing
        case 'callout:help-for-healing':
            switch (page) {
                case '/':
                    addMetricTag('hp_large_healing');
                    break;
                case '/physician-finder/':
                    addMetricTag('phyfinder_large_healing');
                    break;
                case '/resources/':
                    addMetricTag('resources_linda_vid_cta');
                    break;
            }
            break;
    }
}

function addMetricTagOnModalClose() {
    // $('#physician-finder-no-action-exit').
    $(document).on('click', function (e) {
        console.log('clicked on document...'); //xxx
        if (!$(e.target).closest('.modal').length) {
            console('click outside!');
        }
    });
}

$(function () {
    // Add tracking code on modal close
    $('#physician-finder-no-action-exit').on('hide.bs.modal', function (e) {
        addMetricTag('exit_finder_stay');
    });
});
